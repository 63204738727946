/* Main color palette and default background image for App */

:root {
  --color1: #f9c80e;
  --color2: #f86624;
  --color3: #ea3546;
  --color4: #662e9b;
  --color5: #43bccd;
}

main {
  background-position: center;
  background-size: cover;
  background-image: url("./assets/mountainBkgd2.png");
  height: 100%;
}
